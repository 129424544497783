// Define enums for user types
export enum UserType {
  PATIENT = 'Patient',
  DOCTOR = 'Doctor',
  SPONSOR = 'Sponsor',
  PLATFORM_OWNER = 'PlatformOwner'
}

export enum ProgramStatus {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ARCHIVED = 'Archived'
}

export enum ProgramStatusColor {
  Draft = 'warning',
  Active = 'success',
  Inactive = 'default',
  Archived = 'default'
}

// Define enums for param types and levels
export enum ParamType {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  Location = 'Location',
  Boolean = 'Boolean'
}

export enum ParamLevel {
  Mandatory = 'Mandatory',
  Basic = 'Basic',
  Additional = 'Additional',
  Advanced = 'Advanced'
}


export enum FormType {
  ProgramRequest = 'ProgramRequest',
  ICF = 'ICF',
  Patient = 'Patient',
  Doctor = 'Doctor'
}

export enum UserGender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other'
}

export enum DoctorVerificationStatus {
  NonVerified = 'Non-verified',
  Pending = 'Pending',
  Verified = 'Verified',
  Rejected = 'Rejected'
}

export enum NotificationType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success'
}

// Define FitStatus enum
export enum FitStatus {
  FULLY_FIT = 'fullyFit',
  PARTIALLY_FIT = 'partiallyFit',
  NOT_FIT = 'notFit'
}
  
export enum ProgramRequestStatus {
  None = 'Not sent',
  Sent = 'Sent',
  Pending = 'Pending',
  Approved = 'Approved',
  Declined = 'Declined',
  Withdrawn = 'Withdrawn',
}

export enum ProgramRequestType {
  Simple = 'Simple',
  Full = 'Full',
}

export enum ProgramRequestStatusColor {
  Pending = 'warning',
  Approved = 'success',
  Declined = 'danger',
  Withdrawn = 'default'
}
