'use client';

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { DoctorProfile, PatientParam, PatientProfile, SponsorProfile, ValidProfiles } from '@/types/models';
import { UserType } from '@/types/modelEnums';
import logger from '@/layers/support/monitoring/logger';
import { fetchpatientParamsDictionary } from '@/layers/presentation/services/patients';
import { User } from '@/types/models';

const LOG_MODULE = 'FE [Presentation / Context / GlobalStateContext]';

interface GlobalStateContextType {
  patientParamsDictionary: PatientParam[];
  isLoadingParams: boolean;
  error: string | null;
  user: User | null;
  profile: ValidProfiles | null;
  selectedOptions: Record<string, number[]>;
  query: string;
  setUser: (user: User | null) => void;
  setProfile: (profile: ValidProfiles | null) => void;
  getDoctorProfile: () => DoctorProfile | null;
  getPatientProfile: () => PatientProfile | null;
  getSponsorProfile: () => SponsorProfile | null;
  setSelectedOptions: React.Dispatch<React.SetStateAction<Record<string, number[]>>>;
  setQuery: (query: string) => void;
}

const GlobalStateContext = createContext<GlobalStateContextType | undefined>(undefined);

export const GlobalStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [patientParamsDictionary, setpatientParamsDictionary] = useState<PatientParam[]>([]);
  const [isLoadingParams, setIsLoadingParams] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<ValidProfiles | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<Record<string, number[]>>({});
  const [query, setQuery] = useState<string>('');

  const getDoctorProfile = (): DoctorProfile | null => {
    return user?.type === UserType.DOCTOR ? profile as DoctorProfile : null;
  };

  const getPatientProfile = (): PatientProfile | null => {
    return user?.type === UserType.PATIENT ? profile as PatientProfile : null;
  };

  const getSponsorProfile = (): SponsorProfile | null => {
    return user?.type === UserType.SPONSOR ? profile as SponsorProfile : null;
  };

  logger.debug(`${LOG_MODULE}`, { patientParamsDictionary, isLoadingParams, error, user, profile });

  useEffect(() => {
    const fetchParams = async () => {
      setIsLoadingParams(true);
      try {
        const { patientParamsDictionary } = await fetchpatientParamsDictionary();
        setpatientParamsDictionary(patientParamsDictionary);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setIsLoadingParams(false);
      }
    };

    fetchParams();
  }, []);

  return (
    <GlobalStateContext.Provider 
      value={{ 
        patientParamsDictionary, 
        isLoadingParams, 
        error, 
        user, 
        profile, 
        setUser, 
        setProfile,
        getDoctorProfile,
        getPatientProfile,
        getSponsorProfile,
        selectedOptions,
        query,
        setSelectedOptions,
        setQuery,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};