import { PatientParam, PatientProfile, PatientParamValues, ProgramRequest, PatientExtended } from '@/types/models';
import { fetchWrapper } from '../utils/fetchWrapper';
import { NewPatientRequest, PatientParamValuesExtended } from '@/types/patients';
import logger from '@/layers/support/monitoring/logger';
import { FitStatus } from '@/types/modelEnums';

const LOG_MODULE = 'FE [Presentation / Services / Patients]';

const API_URL = '/api/users/patients';

export const fetchPatients = async (filters: Record<string, any[]>): Promise<PatientExtended[]> => {
  logger.debug(`${LOG_MODULE} fetchPatients: begin`, { filters });

  try {
    const result = await fetchWrapper.get<PatientExtended[]>(`${API_URL}?filters=${JSON.stringify(filters)}`);

    logger.debug(`${LOG_MODULE} fetchPatients: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} fetchPatients`, { error });
    throw error;
  }
};

export const fetchPatientById = async (id: string): Promise<PatientProfile> => {
  logger.debug(`${LOG_MODULE} fetchPatientById: begin`, { id });

  try {
    const result = await fetchWrapper.get<PatientProfile>(`${API_URL}/${id}`);

    logger.debug(`${LOG_MODULE} fetchPatientById: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} fetchPatientById`, { error });
    throw error;
  }
};

export const createPatient = async (patient: NewPatientRequest): Promise<PatientProfile> => {
  logger.debug(`${LOG_MODULE} createPatient: begin`, { patient });

  try {
    const result = await fetchWrapper.post<PatientProfile>(API_URL, patient);

    logger.debug(`${LOG_MODULE} createPatient: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} createPatient`, { error });
    throw error;
  }
};

export const updatePatient = async (id: string, patient: Partial<PatientProfile>): Promise<PatientProfile> => {
  logger.debug(`${LOG_MODULE} updatePatient: begin`, { id, patient });

  try {
    const result = await fetchWrapper.put<PatientProfile>(`${API_URL}/${id}`, patient);

    logger.debug(`${LOG_MODULE} updatePatient: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} updatePatient`, { error });
    throw error;
  }
};

export const deletePatient = async (id: string): Promise<void> => {
  logger.debug(`${LOG_MODULE} deletePatient: begin`, { id });

  try {
    const result = await fetchWrapper.delete<void>(`${API_URL}/${id}`);

    logger.debug(`${LOG_MODULE} deletePatient: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} deletePatient`, { error });
    throw error;
  }
};

export const fetchpatientParamsDictionary = async (): Promise<{ patientParamsDictionary: PatientParam[] }> => {
  logger.debug(`${LOG_MODULE} fetchpatientParamsDictionary: begin`);

  try {
    const result = await fetchWrapper.get<{ patientParamsDictionary: PatientParam[] }>(`${API_URL}/params`);

    logger.debug(`${LOG_MODULE} fetchpatientParamsDictionary: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} fetchpatientParamsDictionary`, { error });
    throw error;
  }
};

export const getPatientParamBySlug = (params: PatientParam[], slug: string): PatientParam => {
  logger.debug(`${LOG_MODULE} getPatientParamBySlug: begin`, { params, slug });

  try {
    const result = params.find(param => param.slug === slug) as PatientParam;

    logger.debug(`${LOG_MODULE} getPatientParamBySlug: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} getPatientParamBySlug`, { error });
    throw error;
  }
};


export const getPatientParamValuesExtended = (patientParamsDictionary: PatientParam[], selectedOptions: PatientParamValues): PatientParamValuesExtended => {
  logger.debug(`${LOG_MODULE} getPatientParamValuesExtended: begin`, { patientParamsDictionary, selectedOptions });

  const result: PatientParamValuesExtended = {};

  try {
    selectedOptions && Object.keys(selectedOptions).forEach(paramId => {
      const param = patientParamsDictionary.find(p => Number(p.id) === Number(paramId));
      if (param) {
        const values = selectedOptions[paramId].map(optionId => {
          const option = param.options.find(o => Number(o.id) === Number(optionId));
          return {
            value: optionId,
            label: option ? option.option : ''
          };
        })

        result[param.id] = {
          param,
          values 
        };
      }
    });

    logger.debug(`${LOG_MODULE} getPatientParamValuesExtended: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} getPatientParamValuesExtended`, { error });
    throw error;
  }
}

export const updatePatientParamValues = async (id: string, params: PatientParamValues): Promise<PatientProfile> => {
  logger.debug(`${LOG_MODULE} updatePatientParamValues: begin`, { id, params });

  try {
    const result = await fetchWrapper.put<PatientProfile>(`${API_URL}/${id}/params`, params);

    logger.debug(`${LOG_MODULE} updatePatientParamValues: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} updatePatientParamValues`, { error });
    throw error;
  }
};

interface Params {
  [key: string]: number[];
}

export function doesPatientFitProgram(
  patientParams: Params,
  programParams: Params,
  patientParamsDictionary: PatientParam[]
): FitStatus {
  logger.debug(`${LOG_MODULE} doesPatientFitProgram: begin`, { patientParams, programParams, patientParamsDictionary });

  // Filter patientParamsDictionary to include only "Basic" level parameters
  const basicParams = patientParamsDictionary.filter(param => param.level === 'Basic');
  const basicParamIds = new Set(basicParams.map(param => param.id.toString()));

  // Filter programParams to include only those that match the "Basic" level parameters and have non-empty arrays
  const filteredProgramParams = Object.fromEntries(
    Object.entries(programParams).filter(
      ([paramId, options]) => basicParamIds.has(paramId) && options.length > 0
    )
  );

  let matchingBasicCount = 0;
  const totalBasicParamsInProgram = Object.keys(filteredProgramParams).length;

  for (const [paramId, programOptions] of Object.entries(filteredProgramParams)) {
    const patientOptions = patientParams[paramId] || [];

    // If the patient has the required param and at least one option matches, increment the count
    if (patientOptions.length > 0 && programOptions.some(option => patientOptions.includes(option))) {
      matchingBasicCount++;
    }
  }

  const fullyFit = matchingBasicCount === totalBasicParamsInProgram;
  const partiallyFit = matchingBasicCount > 0;

  logger.debug(`${LOG_MODULE} doesPatientFitProgram: end`, {
    basicParams,
    basicParamIds,
    filteredProgramParams,
    matchingBasicCount,
    totalBasicParamsInProgram,
    fullyFit,
    partiallyFit
  });

  if (fullyFit) {
    return FitStatus.FULLY_FIT;
  } else if (partiallyFit) {
    return FitStatus.PARTIALLY_FIT;
  } else {
    return FitStatus.NOT_FIT;
  }
}