import { ValidProfiles } from '@/types/models';
import logger from '@/layers/support/monitoring/logger';
import { UserType } from '@/types/modelEnums';
import { fetchDoctorByUserId, createDoctor } from './doctors';
import { createSponsor, fetchSponsorByUserId } from './sponsors';
import { fetchWrapper } from '../utils/fetchWrapper';
import { SubscriptionParams } from '@/types/users';

const API_URL = '/api/users';
const LOG_MODULE = 'FE [Presentation / Services / users]';

export const getProfile = async (userId: string, userType: UserType): Promise<ValidProfiles | null> => {
  logger.debug(`${LOG_MODULE} getProfile: begin`, { userId, userType });

  try {
    let profile: ValidProfiles | null = null;

    switch (userType) {
      case UserType.DOCTOR:
        profile = await fetchDoctorByUserId(userId);
        if (!profile) {
          profile = await createDoctor({ user_id: userId });
        }
        break;

      case UserType.SPONSOR:
        profile = await fetchSponsorByUserId(userId);
        if (!profile) {
          profile = await createSponsor({ user_id: userId });
        }
        break;

        // Add cases for other profile types as needed
      default:
        throw new Error(`Unsupported user type: ${userType}`);
    }

    profile = enhanceProfileWithType(profile, userType);

    logger.debug(`${LOG_MODULE} getProfile: end`, { profile });
    return profile;

  } catch (error) {
    logger.error(`${LOG_MODULE} getProfile`, { error });
    throw error;
  }
};

/**
 * Enhances a raw profile object with the specified UserType.
 * @param profile - The raw profile object fetched from the backend.
 * @param type - The UserType to assign to the profile.
 * @returns The profile object augmented with the type property.
 */
const enhanceProfileWithType = <T>(profile: T, type: UserType): T & { type: UserType } => {
  return { ...profile, type };
};

export const subscribeUser = async (
  email: string,
  params: SubscriptionParams,
): Promise<boolean> => {
  logger.debug(`${LOG_MODULE} subscribeUser: begin`, { email, params });

  try {
    const result = await fetchWrapper.post<boolean>(`${API_URL}/subscribe`, { email, params });

    logger.debug(`${LOG_MODULE} subscribeUser: end`, { result });
    return result;
  } catch (error) {
    logger.error(`${LOG_MODULE} subscribeUser: error`, { error });
    throw error;
  }
};