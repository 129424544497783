import { DoctorProfile } from '@/types/models';
import { fetchWrapper } from '../utils/fetchWrapper';
import { NewDoctorRequest } from '@/types/doctors';
import logger from '@/layers/support/monitoring/logger';

const LOG_MODULE = 'FE [Presentation / Services / Doctors]';

const API_URL = '/api/users/doctors';

export const fetchDoctorById = async (id: string): Promise<DoctorProfile> => {
  logger.debug(`${LOG_MODULE} fetchDoctorById: begin`, { id });

  try {
    const result = await fetchWrapper.get<DoctorProfile>(`${API_URL}/${id}`);

    logger.debug(`${LOG_MODULE} fetchDoctorById: end`, { result });
    return result;
  } catch (error) {
    logger.error(`${LOG_MODULE} fetchDoctorById`, { error });
    throw error;
  }
};

export const fetchDoctorByUserId = async (id: string): Promise<DoctorProfile> => {
  logger.debug(`${LOG_MODULE} fetchDoctorByUserId: begin`, { id });

  try {
    const result = await fetchWrapper.get<DoctorProfile>(`${API_URL}/user/${id}`);

    logger.debug(`${LOG_MODULE} fetchDoctorByUserId: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} fetchDoctorByUserId`, { error });
    throw error;
  }
};

export const createDoctor = async (doctor: NewDoctorRequest): Promise<DoctorProfile> => {
  logger.debug(`${LOG_MODULE} createDoctor: begin`, { doctor });

  try {
    const result = await fetchWrapper.post<DoctorProfile>(API_URL, doctor);

    logger.debug(`${LOG_MODULE} createDoctor: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} createDoctor`, { error });
    throw error;
  }
};

export const submitDoctorVerification = async (doctorId: string, npi: string): Promise<void> => {
  logger.debug(`${LOG_MODULE} submitDoctorVerification: begin`, { doctorId, npi });

  try {
    await fetchWrapper.put(`${API_URL}/${doctorId}/verify`, { npi });
  } catch (error) {
    logger.error(`${LOG_MODULE} submitDoctorVerification`, { error });
    throw error;
  }
};