import logger from '@/layers/support/monitoring/logger';
import { handleError } from './errorHandler';

const LOG_MODULE = 'FE [Presentation / Utils / fetchWrapper]';

export const fetchWrapper = {
  get: async <T>(url: string): Promise<T> => {
    logger.debug(`${LOG_MODULE} get: begin`, { url });

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json(); // Await the JSON response

      logger.debug(`${LOG_MODULE} get: end`, { data });
      return data;

    } catch (error) {
      logger.error(`${LOG_MODULE} get`, { error });
      throw error;
    }
  },

  post: async <T>(url: string, body: any): Promise<T> => {
    logger.debug(`${LOG_MODULE} post: begin`, { url, body });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error('Failed to post data');
      }
      const data = await response.json();

      logger.debug(`${LOG_MODULE} post: end`, { data });
      return data;

    } catch (error) {
      logger.error(`${LOG_MODULE} post`, { error });
      throw error;
    }
  },

  put: async <T>(url: string, body: any): Promise<T> => {
    logger.debug(`${LOG_MODULE} put: begin`, { url, body });

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error('Failed to update data');
      }
      const data = await response.json();

      logger.debug(`${LOG_MODULE} put: end`, { data });
      return data;

    } catch (error) {
      logger.error(`${LOG_MODULE} put`, { error });
      throw error;
    }
  },

  delete: async <T>(url: string): Promise<T> => {
    logger.debug(`${LOG_MODULE} delete: begin`, { url });

    try {
      const response = await fetch(url, { method: 'DELETE' });
      if (!response.ok) {
        throw new Error('Failed to delete data');
      }
      const data = await response.json();

      logger.debug(`${LOG_MODULE} delete: end`, { data });
      return data;

    } catch (error) {
      logger.error(`${LOG_MODULE} delete`, { error });
      throw error;
    }
  },
};