import { SponsorProfile } from '@/types/models';
import { fetchWrapper } from '../utils/fetchWrapper';
import { NewSponsorRequest } from '@/types/sponsors';
import logger from '@/layers/support/monitoring/logger';

const LOG_MODULE = 'FE [Presentation / Services / Sponsors]';

const API_URL = '/api/users/sponsors';

export const fetchSponsorByUserId = async (id: string): Promise<SponsorProfile> => {
  logger.debug(`${LOG_MODULE} fetchSponsorByUserId: begin`, { id });

  try {
    const result = await fetchWrapper.get<SponsorProfile>(`${API_URL}/user/${id}`);

    logger.debug(`${LOG_MODULE} fetchSponsorByUserId: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} fetchSponsorByUserId`, { error });
    throw error;
  }
};

export const createSponsor = async (sponsor: NewSponsorRequest): Promise<SponsorProfile> => {
  logger.debug(`${LOG_MODULE} createSponsor: begin`, { sponsor });

  try {
    const result = await fetchWrapper.post<SponsorProfile>(API_URL, sponsor);

    logger.debug(`${LOG_MODULE} createSponsor: end`, { result });
    return result;

  } catch (error) {
    logger.error(`${LOG_MODULE} createSponsor`, { error });
    throw error;
  }
};
