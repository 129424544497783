import log from 'loglevel';

// Set default level
const defaultLevel = log.levels.DEBUG;

// Get log level from environment variable or use default
const envLogLevel = process.env.LOG_LEVEL as log.LogLevelDesc;
const logLevel = envLogLevel || defaultLevel;

log.setLevel(logLevel);

const logger = {
  info: (message: string, ...args: any[]) => log.info(message, ...args),
  warn: (message: string, ...args: any[]) => log.warn(message, ...args),
  error: (message: string, ...args: any[]) => log.error(message, ...args),
  debug: (message: string, ...args: any[]) => log.debug(message, ...args),
  setLevel: (level: log.LogLevelDesc) => log.setLevel(level)
};

export default logger;